var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ce-verify"},[_c('transition',{attrs:{"name":"fade"}},[(_vm.resultModalVisible)?_c('div',{staticClass:"ce-verify__result-modal-background",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeResultModal()}}},[_c('div',{staticClass:"ce-verify__result-modal",on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return _vm.closeResultModal()}}},[(_vm.certificateVisible)?_c('Certificate',{attrs:{"data":_vm.certificateContent},on:{"close":function($event){return _vm.closeResultModal()}}}):_vm._e(),(_vm.warningVisible)?_c('Warning',{on:{"close":function($event){return _vm.closeResultModal()}}}):_vm._e()],1)]):_vm._e()]),_c('b-container',[_c('div',{staticClass:"ce-verify__inner"},[_c('form',{staticClass:"ce-verify__form"},[_c('header',{staticClass:"ce-verify__header"},[_c('h1',{staticClass:"ce-verify__title"},[_vm._v(" Verify TCG World Staff ")]),_c('h3',{staticClass:"ce-verify__subtitle"},[_vm._v(" Enter the username of the person who serviced you ")])]),_c('div',{staticClass:"ce-verify__form-content"},[_c('div',{staticClass:"ce-verify__form-content-inner"},[_c('div',{staticClass:"ce-verify__field-container"},[_c('label',{staticClass:"ce-verify__field-label",attrs:{"for":"telegram"}},[_vm._v(" Enter Telegram name ")]),_c('div',{staticClass:"ce-verify__field-container-bottom"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.telegramIdentifier),expression:"telegramIdentifier"}],staticClass:"ce-verify__input-field",attrs:{"type":"text","id":"telegram","placeholder":"@telegramname"},domProps:{"value":(_vm.telegramIdentifier)},on:{"input":function($event){if($event.target.composing){ return; }_vm.telegramIdentifier=$event.target.value}}}),_c('button',{class:{
                    'ce-verify__submit-btn': true,
                    disabled: _vm.telegramIdentifier.length === 0,
                  },attrs:{"disabled":_vm.telegramIdentifier.length === 0},on:{"click":function($event){$event.preventDefault();return _vm.sendRequestToVerifyByPlatform('telegram', _vm.telegramIdentifier)}}},[_c('VueElementLoading',{attrs:{"active":_vm.inputLoadingStates.telegramLoading,"spinner":"bar-fade-scale"}}),_vm._v(" Enter ")],1)])]),_c('div',{staticClass:"ce-verify__field-container"},[_c('label',{staticClass:"ce-verify__field-label",attrs:{"for":"discord"}},[_vm._v(" Enter Discord Server username ")]),_c('div',{staticClass:"ce-verify__field-container-bottom"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.discordIdentifier),expression:"discordIdentifier"}],staticClass:"ce-verify__input-field",attrs:{"type":"text","id":"discord","placeholder":"discordname#1234"},domProps:{"value":(_vm.discordIdentifier)},on:{"input":function($event){if($event.target.composing){ return; }_vm.discordIdentifier=$event.target.value}}}),_c('button',{class:{
                    'ce-verify__submit-btn': true,
                    disabled: _vm.discordIdentifier.length === 0,
                  },attrs:{"disabled":_vm.discordIdentifier.length === 0},on:{"click":function($event){$event.preventDefault();return _vm.sendRequestToVerifyByPlatform('discord', _vm.discordIdentifier)}}},[_c('VueElementLoading',{attrs:{"active":_vm.inputLoadingStates.discordLoading,"spinner":"bar-fade-scale"}}),_vm._v(" Enter ")],1)])]),_c('div',{staticClass:"ce-verify__field-container"},[_c('label',{staticClass:"ce-verify__field-label",attrs:{"for":"twitter"}},[_vm._v(" Enter Twitter username ")]),_c('div',{staticClass:"ce-verify__field-container-bottom"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.twitterIdentifier),expression:"twitterIdentifier"}],staticClass:"ce-verify__input-field",attrs:{"type":"text","id":"twitter","placeholder":"@twittername"},domProps:{"value":(_vm.twitterIdentifier)},on:{"input":function($event){if($event.target.composing){ return; }_vm.twitterIdentifier=$event.target.value}}}),_c('button',{class:{
                    'ce-verify__submit-btn': true,
                    disabled: _vm.twitterIdentifier.length === 0,
                  },attrs:{"disabled":_vm.twitterIdentifier.length === 0},on:{"click":function($event){$event.preventDefault();return _vm.sendRequestToVerifyByPlatform('twitter', _vm.twitterIdentifier)}}},[_c('VueElementLoading',{attrs:{"active":_vm.inputLoadingStates.twitterLoading,"spinner":"bar-fade-scale"}}),_vm._v(" Enter ")],1)])])])]),_c('h5',{staticClass:"ce-verify__form-prompt"},[_vm._v("You'll get results right away")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }